// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blogtest-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/blogtest.jsx" /* webpackChunkName: "component---src-pages-blogtest-jsx" */),
  "component---src-pages-download-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/download.jsx" /* webpackChunkName: "component---src-pages-download-jsx" */),
  "component---src-pages-how-it-works-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/how-it-works.jsx" /* webpackChunkName: "component---src-pages-how-it-works-jsx" */),
  "component---src-pages-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-pages-welcome-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/welcome.jsx" /* webpackChunkName: "component---src-pages-welcome-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/atlassian/pipelines/agent/build/.cache/data.json")

