module.exports = [{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"google":{"families":["Raleway:100,300,400"]}},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
